body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.multiline-ellipsis-text {
  font-size: 14px;
  color: #1d2939;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Maximum number of lines to display */
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
}

.multiline-ellipsis-text-route {
  font-size: 13px;
  color: #1d2939;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Maximum number of lines to display */
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
}

.multiline-ellipsis-header {
  font-size: 14px;
  font-weight: 600;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Maximum number of lines to display */
  -webkit-box-orient: vertical;
  white-space: normal;
  text-align: left;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper-button-prev {
  background-image: url('assets/arrow_prev_pick.svg');
}

.swiper-button-next {
  background-image: url('assets/arrow_next_pick.svg');
}

.swiper-pagination {
  padding-bottom: 48px;
}

.swiper-pagination-bullet {
  width: 64px;
  height: 14px;
  text-align: center;
  line-height: 20px;
  opacity: 1;
  border-radius: 8px;
  background: #d0d5dd;
}

.swiper-pagination-bullet-active {
  background: var(--main-color);
}

.stop-line-first {
  position: absolute;
  margin-top: 35px;
  margin-left: 7.5px;
  height: 40px;
  width: 2px;
  background-color: #d0d5dd;
}

.stop-line {
  position: absolute;
  margin-left: 7.5px;
  height: 45px;
  width: 2px;
  background-color: #d0d5dd;
}

.stop-line-last {
  position: absolute;
  margin-left: 7.5px;
  margin-bottom: 25px;
  height: 30px;
  width: 2px;
  background-color: #d0d5dd;
}

#agents {
  scroll-margin-top: 70px;
}

.slide-in {
  transform: translateY(-100%);
  transition: transform 5s ease-in-out;
}

.slide-in.show {
  transform: translateY(0%);
  transition: transform 5s ease-in-out;
}

@media (max-width: 768px) {
  .routes::-webkit-scrollbar {
    display: none;
  }

  .modal::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 960px) {
  .swiper-pagination {
    padding-bottom: 8px !important;
  }
}
